<template>
  <div class="building-item" @click="itemClick">
    <div class="item-img">
      <img :src="item.coverPic ? item.coverPic : require('../assets/images/placeholder1.png') " alt="" />
    </div>
    <div class="item-content">
      <div class="item-title">{{ item.title }}</div>
      <div class="item-msg">{{ item.address }}</div>
      <div class="item-label">
        <!-- <span v-for="(item01, index) in item.label" :key="index">{{item01}}</span> -->
        <span v-if="item.status === 0" class="status2">待售</span>
        <span v-if="item.status === 1" class="status1">在售</span>
        <span v-if="item.status === 2" class="status3">售罄</span>
      </div>
      <div class="item-price">
        <div class="total-price">{{ item.avPrice }}元/m²</div>
        <div class="average-price">建面{{ item.buildArea }}m²</div>
      </div>
    </div>
    <div class="item-active" v-if="item.concessional">{{item.concessional|ellipsis}}</div>
  </div>
</template>
<script>
export default {
  name: "buildingItem",
  data() {
    return {
      id: "",
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 8) {
        return value.slice(0, 8) + '...'
      }
      return value
    }
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {
        };
      },
    },
    types: {
      type: Number,
    }
  },
  methods: {
    itemClick() {
      if(this.types == 1){
        sessionStorage.setItem("buildingType",true)
      }else{
        sessionStorage.setItem("buildingType",false)
        
      }
      this.$router.push({
        path: "/buildingDetail",
        query: {
          id: this.id,
        },
      });
    },
  },
  mounted() {
    this.id = this.item.id;
  },
  watch: {
    item: function (newData) {
      this.id = newData.id;
    },
  },
};
</script>
<style lang="less" scoped>
.building-item {
  position: relative;
  .item-active {
    position: absolute;
    left: 0;
    top: 8px;
    color: #fff;
    font-size: 12px;
    background: #ff5742;
    line-height: 22px;
    padding: 0 5px;
    border-radius: 5px;
    // width:110x;
    // overflow: hidden;
    // word-break: break-all;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  div {
    line-height: 1;
  }
  display: flex;
  .item-img {
    width: 120px;
    height: 80px;
    margin-right: 10px;
    img {
      width: 120px;
      height: 80px;
    }
  }
  .item-content {
    flex: 1;
    height: 80px;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    .item-title {
      color: #333;
      font-size: 16px;
      line-height: 16px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item-msg {
      color: #858585;
      font-size: 14px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
    }
    .item-label {
      color: #fff;
      font-size: 12px;

      .status1 {
        display: inline-block;
        padding: 0 5px;
        line-height: 15px;
        border-radius: 3px;
        background-color: #87cefa;
        margin-right: 5px;
      }
      .status2 {
        display: inline-block;
        padding: 0 5px;
        line-height: 15px;
        border-radius: 3px;
        background-color: #00d3c4;
        margin-right: 5px;
      }
      .status3 {
        display: inline-block;
        padding: 0 5px;
        line-height: 15px;
        border-radius: 3px;
        background-color: #808080;
        margin-right: 5px;
      }
    }
    .item-price {
      display: flex;
      align-items: center;
      .total-price {
        color: #ff5742;
        font-size: 16px;
        margin-right: 5px;
      }
      .average-price {
        color: #878787;
        font-size: 13px;
      }
    }
  }
}
</style>