<template>
  <div class="house-item" @click="itemClick">
    <div class="item-img">
      <img :src="images ? images : require('../assets/images/placeholder1.png') " alt=""/>
    </div>
    <div class="item-content">
<!--      <div><h1>{{type}}</h1></div>-->
      <div class="item-title">{{ escapeHtml(item.title) }}</div>
      <div class="item-msg">
        <span>{{ item.houseArea}}m²</span>/<span>{{ item.orientation }}</span>/<span>{{ item.floor }}层</span>
      </div>
       <div class="item-label">
        <!-- <span v-for="(item01, index) in item.label" :key="index">{{item01}}</span> -->
        <!-- <span v-if="item.status === 0" class="status2">待售</span> -->
        <span v-if="item.status === 0" class="status1">在售</span>
        <!-- <span v-if="item.status === 2" class="status3">售罄</span> -->
      </div>
      <div class="item-price">
        <div class="total-price" :style="{'color':color}">{{ item.price==0?'面议': item.price+"万元"}}</div>
        <div class="average-price" v-if="item.houseArea&&item.price!=0">
          {{ item.houseArea }}m²
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {escapeHtml } from "../common/validate";

export default {
  name: "houseItem",
  data() {
    return {
      images:"",
    };
  },
  props:{
      item:{
          type:Object,
          default:function(){

              return {

              }
          }
      },
      label:{
          type:Boolean,
          default:true
      },
      color:{
          type:String,
          default:"#ff5742"
      },
    types:{
        type:Number,
    }
  },
  methods:{
    escapeHtml,
    itemClick(){
      if(this.types == 1){
        sessionStorage.setItem("houseType",true)
      }else{
        sessionStorage.setItem("houseType",false)
        
      }
      this.$router.push({
        path: "/SecondHouseDetail",
        query: {
          id: this.id,
        },
      });
    }
  },
  mounted() {
    var images= this.item.housePic?this.item.housePic.split(",") : '';
    this.images = images[0]
    this.id = this.item.id
  },
  watch: {
    item: function (newData) {
      this.id = newData.id
    }
  }

};
</script>
<style lang="less" scoped>
 .house-item {
        display: flex;
        // padding: 10px 0 20px;
        // border-bottom: 1px solid #eee;
        &:last-child {
          border-bottom: 0;
        }
        .item-img {
          width: 120px;
          height: 80px;
          margin-right: 10px;
          img {
            width: 120px;
            height: 80px;
          }
        }
        .item-content {
          flex: 1;
          height: 80px;
          width: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .item-title {
            color: #333;
            font-size: 16px;
            line-height: 16px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .item-msg {
            color: #858585;
            font-size: 14px;
            line-height: 14px;
          }
          .item-label {
            color: #fff;
            font-size: 12px;

            span {
              display: inline-block;
              padding: 0 5px;
              line-height: 15px;
              border-radius: 3px;
              background-color: #87cefa;
              margin-right: 5px;
            }
          }
          .item-price {
            display: flex;
            align-items: center;
            .total-price {
              color: #ff5742;
              font-size: 16px;
              margin-right: 5px;
            }
            .average-price {
              color: #878787;
              font-size: 13px;
            }
          }
        }
      }
</style>