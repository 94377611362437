import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { request } from "./network/request"
import { post } from "./network/Interface"
import  timestampToTime from './utils/utils';
import  { getShareInfo } from './utils/wxShare';

import { Step, Steps } from 'vant';
import wx from 'weixin-js-sdk'; // 引入微信SDK
import xss from 'xss'
Vue.use(Step);
Vue.use(Steps);


import "./assets/css/base.css"
import 'amfe-flexible'
import VueQuillEditor from 'vue-quill-editor'
import axios from "axios";
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


Vue.use(VueQuillEditor);
Vue.prototype.$request = request
Vue.prototype.$post = post
Vue.prototype.$wx = wx

let options = {
  stripIgnoreTagBody: true, // 不在白名单中的标签以及标签里面的内容直接删除
  whiteList: {
      h1: ["style","class"],
      h2: ["style","class"],
      h3: ["style","class"],
      h4: ["style","class"],
      h5: ["style","class"],
      h6: ["style","class"],
      hr: ["style","class"],
      span: ["style","class"],
      strong: ["style","class"],
      b: ["style","class"],
      i: ["style","class"],
      br: [],
      p: ["style","class"],
      u: ["style","class"],
      s: ["style","class"],
      sub: ["style","class"],
      sup: ["style","class"],
      pre: ["style","class"],
      code: ["style","class"],
      a: ["style", "target", "href", "title", "rel","class"],
      img: ["style", "src", "title","class"],
      div: ["style","class"],
      table: ["style", "width", "border","class"],
      tr: ["style","class"],
      td: ["style", "width", "colspan","class"],
      th: ["style", "width", "colspan","class"],
      tbody: ["style","class"],
      ul: ["style","class"],
      li: ["style","class"],
      ol: ["style","class"],
      dl: ["style","class"],
      dt: ["style","class"],
      em: ["style","class"],
      cite: ["style","class"],
      section: ["style","class"],
      header: ["style","class"],
      footer: ["style","class"],
      blockquote: ["style","class"],
      audio: ["autoplay", "controls", "loop", "preload", "src","class"],
      video: [
        "autoplay",
        "controls",
        "loop",
        "preload",
        "src",
        "height",
        "width",
        "class"
      ],
   },
   css: {
   // 因为上面白名单中允许了标签的style属性，所以需要防止攻击者使用此途径进行攻击
      whiteList: {
        color: true,
        "background-color": true,
        width: true,
        height: true,
        "max-width": true,
        "max-height": true,
        "min-width": true,
        "min-height": true,
        "font-size": true,
        "font-weight": true,

      },
  },
}
Vue.prototype.$xss = function(value){
  return xss(value,options)
}


// Object.defineProperty(Vue.prototype, '$xss', {
//   value: xss
// })

Vue.prototype.$getShareInfo = getShareInfo

import { Button,Empty, RadioGroup, Radio ,NoticeBar,Checkbox,ImagePreview,List ,Tag , CheckboxGroup, Tab, Tabs,Dialog,Icon,Toast ,Uploader ,Picker,Popup,Notify,NavBar,DropdownMenu, DropdownItem,Swipe, SwipeItem,Grid, GridItem, Tabbar, TabbarItem,Search, Col, Row, Cell, CellGroup,Field  } from 'vant';


Vue.use(DropdownMenu).use(Radio).use(RadioGroup).use(NoticeBar).use(ImagePreview).use(Checkbox).use(Tag).use(List).use(CheckboxGroup).use(Tab).use(Tabs).use(Empty).use(Dialog).use(Icon).use(Uploader).use(Toast).use(Popup).use(Picker).use(Notify).use(NavBar).use(DropdownItem).use(CellGroup).use(Cell).use(Button).use(Swipe).use(SwipeItem).use(Grid).use(GridItem).use(Tabbar).use(TabbarItem).use(Search).use(Col).use(Row).use(Field);
Vue.config.productionTip = false
Vue.prototype.$axios = axios
window.sessionStorage.setItem("EntryUrl",window.location.href.split('#')[0])

Vue.prototype.getTimes = timestampToTime;


let startApp = function () {
  axios.get('/config.json').then((res) => {
    // 基础地址
    Vue.prototype.BASE = res.data;
    Vue.prototype.isLogin =function(){
      const token = window.localStorage.getItem("token");
      // const local = window.location.href;
      if(!token || token == "undefined"){
      window.sessionStorage.setItem("skipAddress",encodeURIComponent(window.location.href))

        Toast.fail({
            message: '请先进行微信授权！',
            duration:1000,
            onClose(){
                // window.location.href = `${res.data.WECHAT_AUTHORIZE_URL}?returnUrl=${local}`;
                window.location.href = Vue.prototype.BASE.BASE_URL + '/empower'

            }
        }); 
        return false
      } else {
        return true
      }
    }
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
    
  })
}

startApp()



Vue.filter('phoneFilter',function(val){
  if(!val){
    return "";
  }
  let reg = /^(.{3}).*(.{4})$/;
  return val.toString().replace(reg, "$1****$2");
})
