<template>
  <div>
    <van-tabbar 
      sticky
      route
      v-model="active"
      active-color="#00D3C4"
      inactive-color="#888"
      @change="onChange"
    >
      <van-tabbar-item
        replace
        :to="item.path"
        v-for="item in bottomNav"
        :key="item.id"
      >
        <span>{{ item.text }}</span>
        <template #icon="props">
          <img :src="props.active ? item.activeUrl : item.imgUrl" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>

export default {
  name: "bottomNav",
  data() {
    return {
      active: 0,
      bottomNav: [
        {
          id: 0,
          imgUrl: require("../assets/images/icon_bottom/icon_home_default@2x.png"),
          activeUrl: require("../assets/images/icon_bottom/icon_home_selected@2x.png"),
          text: "首页",
          path: "/",
        },
        {
          id: 1,
          imgUrl: require("../assets/images/icon_bottom/icon_find_default@2x.png"),
          activeUrl: require("../assets/images/icon_bottom/icon_find_selected@2x.png"),
          text: "快速找房",
          path: "/search",
        },
        {
          id: 2,
          imgUrl: require("../assets/images/icon_bottom/icon_add_default@2x.png"),
          activeUrl: require("../assets/images/icon_bottom/icon_add_selected@2x.png"),
          text: "发布房源",
          path: "/release",
        },
        {
          id: 3,
          imgUrl: require("../assets/images/icon_bottom/icon_me_default@2x.png"),
          activeUrl: require("../assets/images/icon_bottom/icon_me_selected@2x.png"),
          text: "我的",
          path: "/my/index",
        },
      ],
    };
  },
  methods: {
    onChange(index) {
      if (index == 3) {
      
      }
    },
   
  },
};
</script>
<style lang="less" scoped>
.van-tabbar{
  z-index: 9999;
  height: 60px;
  
}

</style>