<template>
   <div class="home">
    <!-- 顶部搜索模块 -->
    <div class="search flex-center" :style="{ background: searchColor ? '' : '#fff' }">
      <div class="searchbox flex-center" :style="{ background: searchColor ? '' : '#f2f2f2' }">
        <div class="addressbox flex-center" @click="goAddress">
          <div class="addressText">
            <span>{{ city }}</span>
          </div>
          <img src="../assets/images/jiantou.png" alt="" />
        </div>
        <div class="inputbox flex-center" @click="goSearch">
          <img src="../assets/images/search.png" alt="" />
          <div class="inputText">请输入小区或地址</div>
        </div>
      </div>
      <div class="dt" @click="goMap" v-if="searchColor">
        <img src="../assets/images/dingwei.png" alt="" />
      </div>
      <div class="dtt" @click="goMap" v-else>
        <img src="../assets/images/dingwei01.png" alt="" />
      </div>
    </div>
    <!-- 顶部轮播图 -->
    <van-swipe class="my-swipe" indicator-color="#007aff">
      <van-swipe-item v-for="item in swipes" :key="item.id"  @click="goBannerDetail(item.pageUrl)">
        <img :src="item.img" alt="" />
      </van-swipe-item>
    </van-swipe>
    <!-- 顶部导航跳转区域 -->
    <van-grid :border="false" :column-num="4">
      <van-grid-item v-for="item in navList" :key="item.id" :to="item.openUrl">
        <img :src="item.icon" alt="" />
        <p>{{ item.title }}</p>
      </van-grid-item>
    </van-grid>
    <!-- 头条模块 -->

    <div class="consult">
      <van-notice-bar :left-icon="consultImg" :scrollable="false" background="#fff" color="#444">
        <van-swipe vertical class="notice-swipe" :autoplay="5000" :show-indicators="false">
          <van-swipe-item @click="goConsult(item.id)" v-for="item in consultList" :key="item.id">{{ item.title }}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>

    </div>
    <!-- 图片链接跳转区域 -->
    <div class="nav-img">
      <div v-for="item in imgNavs.slice(0,3)" :key="item.id" @click="goShowcaseDetail(item.pageUrl)">
        <img :src="item.img" alt="" />
      </div>
    </div>
    <!-- 热门楼盘 -->
    <div class="building box">
      <div class="box-top">
        <div class="title">热门楼盘</div>
        <div class="more" @click="goHouseDetail">更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="box-content">
        <div class="box-item" v-for="item in buildingList.slice(0,6)" :key="item.id">
          <building-item :item="item" :id="item.id" />
        </div>
      </div>
      <div class="box-bottom">
        <div class="bottom-more" @click="goHouseDetail">查看更多</div>
        <img src="../assets/images/bottom-more.png" alt="" />
      </div>
    </div>

    <!-- 房源推荐 -->
    <div class="house box">
      <div class="box-top">
        <div class="title">房源推荐</div>
        <div class="more" @click="goSecondHouseDetail">更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="box-content">
        <div class="box-item" v-for="item in houseList.slice(0,6)" :key="item.id">
          <house-item :item="item" :id="item.id" />
        </div>
      </div>

      <div class="box-bottom">
        <div class="bottom-more" @click="goSecondHouseDetail">查看更多</div>
        <img src="../assets/images/bottom-more.png" alt="" />
      </div>
    </div>
       <!-- 底部导航 -->
    <bottom-nav></bottom-nav>
  </div>
 
</template>

<script>
import bottomNav from "../components/bottomNav.vue";
import houseItem from "../components/HouseItem.vue";
import buildingItem from "../components/BuildingItem.vue";
import { getPageData } from "../network/Interface";

export default {
  name: "Home",
  data() {
    return {
      consultImg: require("../assets/images/consult.png"),
      city: "厦门",
      swipeList: [
        {
          id: 0,
          imgUrl: require("../assets/images/swiper01.jpg"),
        },
        {
          id: 1,
          imgUrl: require("../assets/images/swiper02.png"),
        },
        {
          id: 2,
          imgUrl: require("../assets/images/swiper03.jpg"),
        },
      ],
      navList: [
        {
          id: 0,
          navImg: require("../assets/images/nav01.png"),
          navText: "新房",
          path: "/buildingList",
        },
        {
          id: 1,
          navImg: require("../assets/images/nav02.png"),
          navText: "二手房",
          path: "/SecondHandHouse",
        },
      ],
      imgNavList: [
        {
          id: 0,
          imgUrl: require("../assets/images/navimg01.png"),
          href: "http://www.baidu.com",
        },
        {
          id: 1,
          imgUrl: require("../assets/images/navimg02.png"),
          href: "http://www.baidu.com",
        },
        {
          id: 2,
          imgUrl: require("../assets/images/navimg03.png"),
          href: "http://www.baidu.com",
        },
      ],
      houseList: [],
      buildingList: [],
      consultList: [],
      searchColor: true,
    };
  },
  created() {
    if (this.$route.query.city) {
      this.city = this.$route.query.city;
    }
    //  this.getToken();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    const token = window.localStorage.getItem("token");
    console.log(token);
    this.getPageData();
    this.getHouseList();
    this.getSecondHouseList();
  },
  methods: {
    goAddress() {
    
      this.$router.push("/city");
    },
    goSearch() {
     
      this.$router.push("/Search");
    },
    goMap() {
     
      this.$router.push("/map");
    },
    goConsult(id) {
      this.$router.push({
        path: "/ConsultDetail",
        query: {
          id,
        },
      });
    },
    //轮播图跳转
    goBannerDetail(url){
      if(url && url != "/"){
        this.$router.push(url);
      }
    },
    //橱窗跳转
    goShowcaseDetail(url){
      if(url && url != "/"){
        this.$router.push(url);
      }
    },
    //楼盘跳转
    goHouseDetail() {

      this.$router.push("/BuildingList");
    },
    //二手房跳转
    goSecondHouseDetail() {
      let id = 1;
      this.$router.push({
        path: "/secondHouseList",
        query: {
          id: id,
        },
      });
    },
    // gohousedetail(id){
    //   this.$router.push({
    //     path:"/SecondHouseDetail",
    //     query:{
    //       id
    //     }
    //   })
    // },
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 100) {
        this.searchColor = false;
      } else {
        this.searchColor = true;
      }
    },

    // 获取首页数据
    getPageData() {
      // getPageData().then(res => {
      //   if(res.status == 200){

      //   }
      // })

      this.$post("home/getPageData").then((res) => {
        if (res.status == 200) {
          this.swipeList = res.data.banners;
          this.consultList = res.data.houseInformations;
          this.imgNavList = res.data.showcases;
          this.navList = res.data.homeNavs;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
    // 获取楼盘列表
    getHouseList() {
      console.log("获取到的小区", this.city);
      const data={
        areaName: this.city
      }
      this.$post("home/getHouseList",data).then((res) => {
        if (res.status == 200) {
          console.log(res.data.houses);
          this.buildingList = res.data.houses;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },

    //获取二手房列表
    getSecondHouseList() {
      this.$post("secondHouse/getSecondHouseList").then((res) => {
        if (res.status == 200) {
          this.houseList = res.data.secondHouses;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  components: {
    bottomNav,
    houseItem,
    buildingItem,
  },
  computed: {
    swipes: function () {
      var self = this;
      return this.swipeList.filter(function (item) {
        return item.isShow; //返回isShow=true的项，添加到swipes数组
      });
    },
    imgNavs: function () {
      var self = this;
      return this.imgNavList.filter(function (item) {
        return item.isShow; //返回isShow=true的项，添加到imgNavs数组
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  padding-bottom: 60px;
  // height:100vh;
  .van-swipe-item {
    img {
      width: 100%;
      vertical-align: middle;
      height: 188px;
    }
  }
  .van-grid {
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    .van-grid-item {
      img {
        width: 40px;
        height: 40px;
      }
      p {
        color: #777;
        font-size: 14px;
        line-height: 14px;
        margin-top: 10px;
      }
    }
  }

  /deep/.van-swipe__indicator {
    width: 8px;
    height: 8px;
  }
  /deep/.van-grid-item__content {
    padding: 0.3rem;
  }
  .search {
    background: -webkit-gradient(
      linear,
      0 0,
      0 bottom,
      from(rgba(0, 0, 0, 0.5)),
      to(rgba(0, 0, 0, 0))
    );
    opacity: 0.96;
    padding: 10px 15px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    a {
      display: inline-block;
    }
    .searchbox {
      flex: 1;
      background-color: #fff;
      margin-right: 15px;
      height: 35px;
      border-radius: 5px;
      padding-left: 10px;
      .addressbox {
        .addressText {
          // width: 45px;
          // text-align: center;
          font-size: 14px;

          color: #545454;
        }
        img {
          width: 6px;
          height: 6px;
          margin: 0 5px;
        }
      }
      .inputbox {
        position: relative;
        padding-left: 5px;
        &::after {
          content: "";
          width: 1px;
          height: 15px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          background-color: #e3e3e3;
        }
        .inputText {
          font-size: 14px;
          color: #545454;
        }
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
    }
    .dt,
    .dtt {
      width: 18px;
      height: 18px;
      display: flex;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .consult {
    padding: 10px 15px;
    box-sizing: border-box;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;

    /deep/.van-notice-bar {
      padding: 0;
      display: flex;
      width: 100%;
      .van-icon {
        font-size: 40px;
        margin-right: 15px;
      }
      .notice-swipe {
        height: 40px;
        line-height: 40px;
      }
      .van-swipe__track {
        .van-swipe-item {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    // .consult-img {
    //   width: 40px;
    //   height: 40px;
    //   margin-right: 15px;
    //   img {
    //     width: 40px;
    //     height: 40px;
    //   }
    // }
    // .consult-content {
    //   flex: 1;
    //   height: 40px;
    //   overflow: auto;

    //   .consult-list {
    //     li {
    //       color: #444;
    //       font-size: 13px;
    //       line-height: 20px;
    //     }
    //   }
    // }
  }
  .nav-img {
    padding: 0 15px 10px;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    height: 78px;
    div {
      display: block;
      box-sizing: border-box;
      margin: 0 5px;
      width: 33.33%;
      height: 68px;

      img {
        width: 100%;
        height: 100%;
      }
      &:last-child,
      &:first-child {
        margin: 0;
      }
    }
  }
  .box {
    background-color: #fff;
    padding: 10px 15px env(safe-area-inset-bottom);
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    .box-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #333;
        font-size: 16px;
        padding-left: 15px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 14px;
          border-radius: 2px;
          background-color: #09d5c6;
        }
      }
      .more {
        color: #aaa;
        font-size: 13px;
      }
    }
    .box-content {
      div {
        line-height: 1;
      }
      .box-item {
        // display: flex;
        padding: 10px 0 20px;
        border-bottom: 1px solid #eee;
      }
    }
    .box-bottom {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding-bottom:60px;
      // padding-bottom: env(safe-area-inset-bottom);
      .bottom-more {
        color: #777;
        font-size: 14px;
        margin-right: 5px;
      }
      img {
        width: 15px;
        height: 15px;
        vertical-align: middle;
      }
    }
  }
  // .bottom-box{
  //   height:40px;
  // }
}
</style>