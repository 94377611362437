import Vue from "vue";
import axios from "axios";
import {
    Toast
} from 'vant';


export function request(config,option) {
    const instance = axios.create({
        baseURL: Vue.prototype.BASE.API_URL,
        timeout: 60000
    })

    instance.interceptors.request.use(config => {
        var cs_arr = config.data.split('&'); //参数字符串分割为数组
        var cs = {};
        for (var i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
            cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
        }
        var toast = option.toast; //这样就拿到了参数中的数据
        var name = option.toastName ? option.toastName : '' ;
        if (toast) {
            Toast.loading({
                message:name,
                forbidClick: true,
                loadingType: 'spinner',
                duration: 0,
                className: "checkToast"
            });
        }

        const token = window.localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = token
        }
        return config
    }, err => {

    })

    instance.interceptors.response.use(res => {
        Toast.clear();
        const {
            status
        } = res.data;
        if (status == 700) {
            // var local = window.location.href;
            window.sessionStorage.setItem("skipAddress",encodeURIComponent(window.location.href))
            Toast.fail({
                message: '授权失效\n请重新授权！',
                duration: 1000,
                onClose() {
                    // window.location.href = `${ Vue.prototype.BASE.WECHAT_AUTHORIZE_URL}?returnUrl=${local}`;
                    window.location.href = Vue.prototype.BASE.BASE_URL + '/empower'
                }
            });

        } else {

            return res.data
        }

    }, err => {

    })

    return instance(config)
}