import { request } from "./request"
import qs from 'qs';

// 获取数据
export function getPageData() {
    return request({
        url: "home/getPageData",
        method: "GET",

    })
}



export function post(url,data,option) {
    if(typeof option == 'undefined'){
        option = {};
    }
    return request({
        url: url,
        method: "post",
        data:qs.stringify(data),
    },option)
}

