import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import {
  Toast
} from 'vant';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home

  },
  {
    path: '/my',
    name: 'My',
    component: () => import('../views/my/My.vue'),

    children: [{
        path: 'resources',
        name: 'MyResources',
        component: () => import('../views/my/Resources.vue')
      },
      {
        path: 'index',
        name: 'Index',
        component: () => import('../views/my/Index.vue'),
        meta: {
          auth: 1 //添加字段判断该页面是否需要授权(1为跳转前判断，2为跳转后判断)
        },
      },
      {
        path: 'revise',
        name: 'Revise',
        component: () => import('../views/my/Revise.vue'),
      },
      {
        path: 'property',
        name: 'Property',
        component: () => import('../views/my/Property.vue')
      },
      {
        path: 'barcode',
        name: 'Barcode',
        component: () => import('../views/my/Barcode.vue')
      },

      {
        path: 'followUp',
        name: 'FollowUp',
        component: () => import('../views/my/FollowUp.vue')
      },
      {
        path: 'listingReceipt',
        name: 'ListingReceipt',
        component: () => import('../views/my/ListingReceipt.vue')
      },
      {
        path: 'followDetail',
        name: 'FollowDetail',
        component: () => import('../views/my/FollowDetail.vue')
      },

      {
        path: 'resources',
        name: 'Resources',
        component: () => import('../views/my/Resources.vue')
      },
      {
        path: 'collect',
        name: 'MyCollect',
        component: () => import('../views/my/Collect.vue')
      },
      {
        path: 'counter',
        name: 'Counter',
        component: () => import('../views/my/Counter.vue')
      },
      {
        path: 'bindMch',
        name: 'bindMch',
        component: () => import('../views/my/BindMch.vue')
      },
      {
        path: 'houseAssessment',
        name: 'houseAssessment',
        component: () => import('../views/my/houseAssessment.vue')
      },
      {
        path: 'LoanProgressQuery',
        name: 'LoanProgressQuery',
        component: () => import('../views/my/LoanProgressQuery.vue')
      },
      {
        path: 'Certification',
        name: 'Certification',
        component: () => import('../views/my/Certification.vue')
      },
      {
        path: 'MyCertification',
        name: 'MyCertification',
        component: () => import('../views/my/MyCertification.vue')
      },
      {
        path: 'Order',
        name: 'Order',
        component: () => import('../views/my/Order.vue')
      },
      {
        path: 'MyResource',
        name: 'MyResource',
        component: () => import('../views/my/MyResource.vue')
      },
      // {
      //   path: 'Success',
      //   name: 'Success',
      //   component: () => import('../views/my/Success.vue')
      // },
    ]
  },

  {
    path: '/city',
    name: 'City',
    component: () => import('../views/City.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/release',
    name: 'Release',
    component: () => import('../views/Release.vue'),
    meta: {
      keepAlive: true,
      auth:1
    },

  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: () => import('../views/Assessment.vue')
  },
  {
    path: '/buildingList',
    name: 'BuildingList',
    component: () => import('../views/index/BuildingList.vue')
  },
  {
    path: '/buildingDetail',
    name: 'BuildingDetail',
    component: () => import('../views/index/BuildingDetail.vue'),
    meta: {
      share: true
    }
  },
  {
    path: '/buildingAlbum',
    name: 'BuildingAlbum',
    component: () => import('../views/index/BuildingAlbum.vue')
  },
  {
    path: '/buildingType',
    name: 'BuildingType',
    component: () => import('../views/index/BuildingType.vue')
  },
  {
    path: '/buildingDynamic',
    name: 'BuildingDynamic',
    component: () => import('../views/index/BuildingDynamic.vue')
  },
  {
    path: '/buildingVideo',
    name: 'BuildingVideo',
    component: () => import('../views/index/BuildingVideo.vue')
  },
  {
    path: '/videoDetail',
    name: 'VideoDetail',
    component: () => import('../views/index/VideoDetail.vue'),
    meta: {
      share: true
    }
  },
  {
    path: '/ConsultDetail',
    name: 'ConsultDetail',
    component: () => import('../views/index/ConsultDetail.vue'),
    meta: {
      share: true
    }
  },
  {
    path: '/buildingSubscribe',
    name: 'BuildingSubscribe',
    component: () => import('../views/index/BuildingSubscribe.vue')
  },
  {
    path: '/secondHandHouse',
    name: 'SecondHandHouse',
    component: () => import('../views/index/SecondHandHouse.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/index/MapBox.vue')
  },
  {
    path: '/orderHouse',
    name: 'OrderHouse',
    component: () => import('../views/index/OrderHouse.vue')
  },
  {
    path: '/SecondHouseDetail',
    name: 'SecondHouseDetail',
    component: () => import('../views/index/SecondHouseDetail.vue'),
    meta: {
      share: true
    }
  },
  {
    path: '/SecondHouseList',
    name: 'SecondHouseList',
    component: () => import('../views/index/SecondHouseList.vue')
  },
  {
    path: '/MapDetail',
    name: 'MapDetail',
    component: () => import('../views/index/MapDetail.vue')
  },
  {
    path: '/empower',
    name: 'Empower',
    component: () => import('../views/Empower.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: "history",
  base: '/prod_lej/wap', //添加访问前缀路径
  // 解决页面跳转保存滚动条位置问题
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
});



// 路由守卫
router.beforeEach((to, from, next) => {
  // to要跳转到的路径
  // from从哪个路径来
  // next往下执行的回调
  // 在localStorage中获取token
  let token = localStorage.getItem('token')
  // 判断该页面是否需要授权
  if (to.meta.auth == 1) {
    // 如果token存在直接跳转
    if (token && token != 'undefined') {
      if (!to.meta.share) {
        Vue.prototype.$getShareInfo(document.title, Vue.prototype.BASE.API_URL + '/images/logo.png', 'home')
      }
      next()
    } else {
      // 否则跳转到微信授权页面
      // var local = window.location.href;
      window.sessionStorage.setItem("skipAddress", encodeURIComponent(window.location.href))
      Toast.fail({
        message: '请先进行微信授权！',
        duration: 1000,
        onClose() {
          // window.location.href = `${ Vue.prototype.BASE.WECHAT_AUTHORIZE_URL}?returnUrl=${local}`;
          window.location.href = Vue.prototype.BASE.BASE_URL + '/empower'

        }
      });
    }
  } else {
    // 如果不需要授权，则直接跳转到对应页面
    if (!to.meta.share) {
      Vue.prototype.$getShareInfo(document.title, Vue.prototype.BASE.API_URL + '/images/logo.png', 'home')
    }
    next()
  }
});




// 路由守卫
router.afterEach((to, from) => {
  // to要跳转到的路径
  // from从哪个路径来
  // next往下执行的回调
  // 在localStorage中获取token
  var url = window.location.href; //获取当前url
  var dz_url = url.split('#')[0]; //获取#/之前的字符串
  var cs = dz_url.split('?')[1]; //获取?之后的参数字符串
  if (cs) {
    var cs_arr = cs.split('&'); //参数字符串分割为数组
    var cs = {};
    for (var i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
      cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
    }
    var result = cs.result; //这样就拿到了参数中的数据
    if (result) {
      window.localStorage.setItem("token", result);
    }
  }


  // let token = localStorage.getItem('token')
  // console.log(token);
  // // 判断该页面是否需要授权
  // if (to.meta.auth == 2 && (!token || token == 'undefined')) {
  //   console.log(2222);
  //   // 否则跳转到微信授权页面
  //   var local = window.location.href;
  //   // window.location.href = `http://aboc.ydunicorn.store:9802/wechat/authorize?returnUrl=${local}`;
  //   Toast.fail({
  //       message: '请先进行微信授权！',
  //       duration:1000,
  //       onClose(){
  //           window.location.href = `http://aboc.ydunicorn.store:9802/wechat/authorize?returnUrl=${local}`;
  //       }
  //   });

  // }
})





export default router