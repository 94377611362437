export function isMobile(str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
  return reg.test(str)
}

export function symbolEscape(text) {
  return text.replace(/[<>"&']/g, function (match) {
    switch (match) {
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case "&":
        return "&amp;";
      case "\"":
        return "&quot;";
        case "\'":
          return "&apos;";
    }
  });
}

export function escapeHtml(str) { //转义字符转换普通字符的方法
  var arrEntities = {
    'lt': '<',
    'gt': '>',
    'amp': '&',
    'quot': '"',
    'apos':"'"
  };
  return str.replace(/&(lt|gt|amp|quot|apos);/ig, function (all, t) {
    return arrEntities[t];
  });
}