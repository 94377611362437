// 获取微信配置信息的接口
import { post } from "../network/Interface"
import wx from 'weixin-js-sdk'; // 引入微信SDK
import Vue from "vue";



// 挂载到vue原型，所有vue实例都能继承该方法
export function getShareInfo (title,imgUrl,type) {
    let url = "";
      if (navigator.userAgent.indexOf("iPhone") !== -1) {
        url = window.sessionStorage.getItem("EntryUrl");
      } else {
        const { href } = window.location;
        url = href.split("#")[0];
      }
      const data = {
        url, //url不能写死
      };
      post("Dgsc/wechatParam", data).then((res) => {
        const link = type == 'home' ?   Vue.prototype.BASE.BASE_URL : res.url ;
       wx.config({
          debug: false, //生产环境需要关闭debug模式
          appId: res.appid, //appId通过微信服务号后台查看
          timestamp: res.timestamp, //生成签名的时间戳
          nonceStr: res.nonceStr, //生成签名的随机字符串
          signature: res.signature, //签名
          jsApiList: [
            //需要调用的JS接口列表
            "checkJsApi", //判断当前客户端版本是否支持指定JS接口
            "onMenuShareTimeline", //分享给好友
            "onMenuShareAppMessage", //分享到朋友圈
            "updateTimelineShareData",
            "updateAppMessageShareData",
          ],
        });
       wx.ready(function () {
         wx.updateTimelineShareData({
            title:title, //分享的标题
            // desc: '朋友圈都被这个刷屏了，你也来晒一晒吧~', // 分享描述
            link: link, //注意这里最好是http访问全路径 要不容易出问题
            imgUrl: imgUrl,

            success: function () {
              // 用户确认分享后执行的回调函数
              // alert("分享成功");
              console.log("执行成功");
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
              // alert("取消分享");
            },
          });
          //分享给朋友
         wx.updateAppMessageShareData({
            title: title, //分享的标题
            // desc: '朋友圈都被这个刷屏了，你也来晒一晒吧~', // 分享描述
            link: link, //注意这里最好是http访问全路径 要不容易出问题
            imgUrl: imgUrl,
            type: "", // 分享类型,music、video或link，不填默认为link
            dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
              // 用户确认分享后执行的回调函数
              console.log("执行成功");
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            },
          });
         wx.error(function (res) {
            // alert(res.errMsg);
          });
        });
      });
}